import React, { useState, useEffect } from "react";
import Particle from "../../Particle"; // Adjust the import path if needed
import Break from "../Break";
import caldeficit from "../../../Assets/caldrfasteen.png";

// Content data with tags
const contentData = [
  // Original entries
  {
    id: 1,
    title: "Teen Calorie Deficit",
    image: "caldeficit", // Reference to imported image
    description: "Dont cut as a teen. Cutting as a teen and huge calorie deficits can be detrimental to development and growth! Click any button below to learn more.",
    tags: ["teen", "nutrition", "cutting", "health", "development", "calories"],
    buttons: [
      { type: "primary", label: "Watch", url: "https://www.youtube.com/watch?v=s24YVTkSl0g" },
      { type: "secondary", label: "Read", url: "https://medium.com/@ogbroly/risks-of-cutting-calories-as-a-teen-fc4a4dea2e6b" },
      { type: "primary", label: "Discord", url: "https://discord.com/channels/960727872887222332/1162356088465788989" }
    ]
  },
  {
    id: 2,
    title: "Cutting Bulking and Supplementation",
    image: null, // Will use SVG placeholder
    description: "Full indepth guide on cutting, bulking and supplementation. Webinar with Kyle, CPT, Click any button below to learn more!",
    tags: ["bulking", "cutting", "nutrition", "supplements", "training", "diet"],
    buttons: [
      { type: "primary", label: "Watch", url: "https://www.youtube.com/watch?v=98VowDBnlnY" },
      { type: "secondary", label: "Read", url: "https://medium.com/@ogbroly/body-transformation-the-guide-to-bulking-and-cutting-1de0c5d8688d" },
      { type: "primary", label: "Discord", url: "https://discord.com/channels/960727872887222332/1162371014236323861" }
    ]
  },
  {
    id: 3,
    title: "Maximum Adaptive Volume",
    image: null, // Will use SVG placeholder
    description: "Are you training too much? Or not enough? Click below to find out and learn more.",
    tags: ["training", "volume", "recovery", "gains", "program", "workout"],
    buttons: [
      { type: "primary", label: "Watch", url: "https://www.youtube.com/watch?v=VqGC4dsk1oo" },
      { type: "secondary", label: "Read", url: "/" },
      { type: "primary", label: "Discord", url: "https://discord.com/channels/960727872887222332/1173408412680335361" }
    ]
  },
  
  // SUPPLEMENT-RELATED RESOURCES
  {
    id: 4,
    title: "Research/Resources for Buying Supplements",
    image: null,
    description: "Guide to researching and finding reliable supplements. Learn what to look for when purchasing supplements.",
    tags: ["supplements", "shopping", "research", "quality", "fitness", "nutrition"],
    buttons: [
      { type: "primary", label: "Watch", url: "/" },
      { type: "secondary", label: "Read", url: "/" },
      { type: "primary", label: "Discord", url: "https://discord.com/channels/960727872887222332/1156939001438142584" }
    ]
  },
  {
    id: 5,
    title: "Understanding Nootropics",
    image: null,
    description: "Learn about cognitive enhancers and their effects on mental performance, focus, and brain health.",
    tags: ["nootropics", "supplements", "cognitive", "brain", "focus", "mental"],
    buttons: [
      { type: "primary", label: "Watch", url: "/" },
      { type: "secondary", label: "Read", url: "/" },
      { type: "primary", label: "Discord", url: "https://discord.com/channels/960727872887222332/1113706032808603710" }
    ]
  },
  {
    id: 6,
    title: "Pre-Workout Ingredient Chart",
    image: null,
    description: "Detailed breakdown of common pre-workout ingredients, their effects, and optimal dosages.",
    tags: ["pre-workout", "supplements", "ingredients", "performance", "energy", "training"],
    buttons: [
      { type: "primary", label: "Watch", url: "/" },
      { type: "secondary", label: "Read", url: "/" },
      { type: "primary", label: "Discord", url: "https://discord.com/channels/960727872887222332/1162359383662022716" }
    ]
  },
  {
    id: 7,
    title: "Optimal Dosing for Pre-Workout",
    image: null,
    description: "Learn the right amounts of pre-workout supplements to take for maximum effectiveness and safety.",
    tags: ["pre-workout", "dosing", "supplements", "caffeine", "performance", "timing"],
    buttons: [
      { type: "primary", label: "Watch", url: "/" },
      { type: "secondary", label: "Read", url: "/" },
      { type: "primary", label: "Discord", url: "https://discord.com/channels/960727872887222332/1109095783816499240" }
    ]
  },
  {
    id: 8,
    title: "Vitamin and Mineral Supplementation Fact Sheet",
    image: null,
    description: "Comprehensive guide to essential vitamins and minerals, their benefits, and when supplementation is necessary.",
    tags: ["vitamins", "minerals", "supplements", "nutrition", "health", "deficiency"],
    buttons: [
      { type: "primary", label: "Watch", url: "/" },
      { type: "secondary", label: "Read", url: "/" },
      { type: "primary", label: "Discord", url: "https://discord.com/channels/960727872887222332/1179474441495908524" }
    ]
  },
  {
    id: 9,
    title: "Creatine",
    image: null,
    description: "Everything you need to know about creatine supplementation, benefits, dosing, and common myths.",
    tags: ["creatine", "supplements", "strength", "muscle", "performance", "recovery"],
    buttons: [
      { type: "primary", label: "Watch", url: "/" },
      { type: "secondary", label: "Read", url: "/" },
      { type: "primary", label: "Discord", url: "https://discord.com/channels/960727872887222332/1109124184069640322" }
    ]
  },
  {
    id: 10,
    title: "Protein Powder",
    image: null,
    description: "Guide to different types of protein powders, their benefits, and how to choose the right one for your goals.",
    tags: ["protein", "supplements", "recovery", "muscle", "nutrition", "whey"],
    buttons: [
      { type: "primary", label: "Watch", url: "/" },
      { type: "secondary", label: "Read", url: "/" },
      { type: "primary", label: "Discord", url: "https://discord.com/channels/960727872887222332/1121452696227028992" }
    ]
  },
  {
    id: 11,
    title: "Beta-Alanine",
    image: null,
    description: "Learn about beta-alanine, its effects on exercise performance, optimal dosing, and the science behind the tingles.",
    tags: ["beta-alanine", "supplements", "endurance", "performance", "pre-workout", "buffering"],
    buttons: [
      { type: "primary", label: "Watch", url: "/" },
      { type: "secondary", label: "Read", url: "/" },
      { type: "primary", label: "Discord", url: "https://discord.com/channels/960727872887222332/1162357878414385194" }
    ]
  },
  {
    id: 12,
    title: "Ashwagandha",
    image: null,
    description: "Discover the benefits of this adaptogenic herb for stress management, testosterone, and overall health.",
    tags: ["ashwagandha", "supplements", "adaptogen", "stress", "hormones", "recovery"],
    buttons: [
      { type: "primary", label: "Watch", url: "/" },
      { type: "secondary", label: "Read", url: "/" },
      { type: "primary", label: "Discord", url: "https://discord.com/channels/960727872887222332/1121454553276100808" }
    ]
  },
  {
    id: 13,
    title: "Iron",
    image: null,
    description: "Understanding iron supplementation, its importance for energy and performance, and when you might need it.",
    tags: ["iron", "supplements", "minerals", "energy", "blood", "anemia"],
    buttons: [
      { type: "primary", label: "Watch", url: "/" },
      { type: "secondary", label: "Read", url: "/" },
      { type: "primary", label: "Discord", url: "https://discord.com/channels/960727872887222332/1121454387966005269" }
    ]
  },
  {
    id: 14,
    title: "Magnesium",
    image: null,
    description: "Learn about this essential mineral's role in muscle function, sleep quality, and overall health.",
    tags: ["magnesium", "supplements", "minerals", "sleep", "recovery", "muscle"],
    buttons: [
      { type: "primary", label: "Watch", url: "/" },
      { type: "secondary", label: "Read", url: "/" },
      { type: "primary", label: "Discord", url: "https://discord.com/channels/960727872887222332/1121454290813329528" }
    ]
  },
  {
    id: 15,
    title: "Fish Oil (OMEGA 3)",
    image: null,
    description: "The benefits of omega-3 fatty acids for inflammation, heart health, and recovery in athletes.",
    tags: ["omega-3", "fish-oil", "supplements", "inflammation", "health", "recovery"],
    buttons: [
      { type: "primary", label: "Watch", url: "/" },
      { type: "secondary", label: "Read", url: "/" },
      { type: "primary", label: "Discord", url: "https://discord.com/channels/960727872887222332/1121454179970461736" }
    ]
  },
  {
    id: 16,
    title: "Vitamin K",
    image: null,
    description: "Understanding the importance of Vitamin K for bone health, cardiovascular function, and overall wellness.",
    tags: ["vitamin-k", "supplements", "bone-health", "cardiovascular", "nutrition", "health"],
    buttons: [
      { type: "primary", label: "Watch", url: "/" },
      { type: "secondary", label: "Read", url: "/" },
      { type: "primary", label: "Discord", url: "https://discord.com/channels/960727872887222332/1121454108163981382" }
    ]
  },
  {
    id: 17,
    title: "Vitamin D",
    image: null,
    description: "The essential guide to Vitamin D supplementation, its role in immune function, hormones, and athletic performance.",
    tags: ["vitamin-d", "supplements", "immune", "hormones", "sunshine", "deficiency"],
    buttons: [
      { type: "primary", label: "Watch", url: "/" },
      { type: "secondary", label: "Read", url: "/" },
      { type: "primary", label: "Discord", url: "https://discord.com/channels/960727872887222332/1121453787865940048" }
    ]
  },
  {
    id: 18,
    title: "Potential Benefits of Removing Caffeine from Diet",
    image: null,
    description: "Explore how reducing or eliminating caffeine might improve your sleep, anxiety levels, and overall health.",
    tags: ["caffeine", "stimulants", "sleep", "anxiety", "health", "energy"],
    buttons: [
      { type: "primary", label: "Watch", url: "/" },
      { type: "secondary", label: "Read", url: "/" },
      { type: "primary", label: "Discord", url: "https://discord.com/channels/960727872887222332/1156917075089043528" }
    ]
  },
  
  // DIET-RELATED RESOURCES
  {
    id: 19,
    title: "Diet Tools and Calculators",
    image: null,
    description: "Resources for calculating your caloric needs, macronutrient targets, and tracking your dietary progress.",
    tags: ["diet", "tools", "calculators", "macros", "calories", "tracking"],
    buttons: [
      { type: "primary", label: "Watch", url: "/" },
      { type: "secondary", label: "Read", url: "/" },
      { type: "primary", label: "Discord", url: "https://discord.com/channels/960727872887222332/1218694029466796032" }
    ]
  },
  {
    id: 20,
    title: "Bulking vs. Cutting: A Comprehensive Guide",
    image: null,
    description: "Everything you need to know about gaining muscle mass or losing fat with proper nutrition strategies.",
    tags: ["bulking", "cutting", "nutrition", "muscle", "fat-loss", "calories"],
    buttons: [
      { type: "primary", label: "Watch", url: "/" },
      { type: "secondary", label: "Read", url: "/" },
      { type: "primary", label: "Discord", url: "https://discord.com/channels/960727872887222332/1162371014236323861" }
    ]
  },
  {
    id: 21,
    title: "Tracking Weight Loss or Gain",
    image: null,
    description: "How to properly monitor changes in your body composition and interpret scale fluctuations.",
    tags: ["tracking", "weight", "progress", "scale", "measurements", "fluctuations"],
    buttons: [
      { type: "primary", label: "Watch", url: "/" },
      { type: "secondary", label: "Read", url: "/" },
      { type: "primary", label: "Discord", url: "https://discord.com/channels/960727872887222332/1157331017129009322" }
    ]
  },
  {
    id: 22,
    title: "What Foods should I be eating?",
    image: null,
    description: "A practical guide to food choices that support your fitness goals and overall health.",
    tags: ["nutrition", "food-choices", "diet", "health", "meal-planning", "whole-foods"],
    buttons: [
      { type: "primary", label: "Watch", url: "/" },
      { type: "secondary", label: "Read", url: "/" },
      { type: "primary", label: "Discord", url: "https://discord.com/channels/960727872887222332/1097849326274871346" }
    ]
  },
  {
    id: 23,
    title: "Must know Cutting Information",
    image: null,
    description: "Essential strategies and tips for successful fat loss while preserving muscle mass.",
    tags: ["cutting", "fat-loss", "dieting", "calories", "nutrition", "deficit"],
    buttons: [
      { type: "primary", label: "Watch", url: "/" },
      { type: "secondary", label: "Read", url: "/" },
      { type: "primary", label: "Discord", url: "https://discord.com/channels/960727872887222332/1143950782492836062" }
    ]
  },
  {
    id: 24,
    title: "The Risks of Cutting as a Teenager",
    image: null,
    description: "Why severe calorie restriction can be harmful during adolescent development and healthier alternatives.",
    tags: ["teen", "cutting", "health", "growth", "development", "nutrition"],
    buttons: [
      { type: "primary", label: "Watch", url: "/" },
      { type: "secondary", label: "Read", url: "/" },
      { type: "primary", label: "Discord", url: "https://discord.com/channels/960727872887222332/1162356088465788989" }
    ]
  },
  {
    id: 25,
    title: "Long Term Fat Loss",
    image: null,
    description: "Sustainable approaches to losing fat and maintaining results for the long haul.",
    tags: ["fat-loss", "sustainability", "habits", "lifestyle", "maintenance", "weight-management"],
    buttons: [
      { type: "primary", label: "Watch", url: "/" },
      { type: "secondary", label: "Read", url: "/" },
      { type: "primary", label: "Discord", url: "https://discord.com/channels/960727872887222332/1151963349861552278" }
    ]
  },
  {
    id: 26,
    title: "The Order you Eat your food in Matters",
    image: null,
    description: "How the sequence of consuming different foods can affect digestion, nutrient absorption, and blood sugar.",
    tags: ["meal-timing", "digestion", "blood-sugar", "nutrition", "food-order", "metabolism"],
    buttons: [
      { type: "primary", label: "Watch", url: "/" },
      { type: "secondary", label: "Read", url: "/" },
      { type: "primary", label: "Discord", url: "https://discord.com/channels/960727872887222332/1222686518720856198" }
    ]
  },
  {
    id: 27,
    title: "How much Protein can your body metabolize in one meal?",
    image: null,
    description: "The science behind protein utilization and optimal meal distribution for muscle growth.",
    tags: ["protein", "metabolism", "muscle", "nutrition", "meal-timing", "absorption"],
    buttons: [
      { type: "primary", label: "Watch", url: "/" },
      { type: "secondary", label: "Read", url: "/" },
      { type: "primary", label: "Discord", url: "https://discord.com/channels/960727872887222332/1157699692294066287" }
    ]
  },
  {
    id: 28,
    title: "The Three Laws of Protein",
    image: null,
    description: "Understanding the fundamental principles of protein consumption for optimal muscle growth and recovery.",
    tags: ["protein", "muscle", "nutrition", "recovery", "synthesis", "amino-acids"],
    buttons: [
      { type: "primary", label: "Watch", url: "/" },
      { type: "secondary", label: "Read", url: "/" },
      { type: "primary", label: "Discord", url: "https://discord.com/channels/960727872887222332/1190410684194893995" }
    ]
  },
  {
    id: 29,
    title: "Is Sugar Bad?",
    image: null,
    description: "A nuanced look at sugar consumption, its effects on health, and practical guidelines for athletes.",
    tags: ["sugar", "carbs", "nutrition", "health", "performance", "diet"],
    buttons: [
      { type: "primary", label: "Watch", url: "/" },
      { type: "secondary", label: "Read", url: "/" },
      { type: "primary", label: "Discord", url: "https://discord.com/channels/960727872887222332/1190412149638574220" }
    ]
  },
  {
    id: 30,
    title: "Understanding Carbohydrates: Simple vs Complex",
    image: null,
    description: "Learn the differences between types of carbs and how they affect energy, performance, and body composition.",
    tags: ["carbs", "nutrition", "energy", "glycemic-index", "diet", "performance"],
    buttons: [
      { type: "primary", label: "Watch", url: "/" },
      { type: "secondary", label: "Read", url: "/" },
      { type: "primary", label: "Discord", url: "https://discord.com/channels/960727872887222332/1162355608868106280" }
    ]
  },
  {
    id: 31,
    title: "Cheap Electrolyte Solution",
    image: null,
    description: "Cost-effective ways to maintain proper electrolyte balance for hydration and performance.",
    tags: ["electrolytes", "hydration", "sodium", "potassium", "performance", "budget"],
    buttons: [
      { type: "primary", label: "Watch", url: "/" },
      { type: "secondary", label: "Read", url: "/" },
      { type: "primary", label: "Discord", url: "https://discord.com/channels/960727872887222332/1085916788472160277" }
    ]
  },
  {
    id: 32,
    title: "High Protein Veggies List",
    image: null,
    description: "Discover plant-based options that provide quality protein for vegetarians and vegans.",
    tags: ["protein", "vegetables", "plant-based", "vegetarian", "vegan", "nutrition"],
    buttons: [
      { type: "primary", label: "Watch", url: "/" },
      { type: "secondary", label: "Read", url: "/" },
      { type: "primary", label: "Discord", url: "https://discord.com/channels/960727872887222332/1109548304573923421" }
    ]
  },
  {
    id: 33,
    title: "How should I eat around my workout?",
    image: null,
    description: "Strategies for pre and post-workout nutrition to maximize performance and recovery.",
    tags: ["nutrition", "workout", "timing", "performance", "recovery", "meal-planning"],
    buttons: [
      { type: "primary", label: "Watch", url: "/" },
      { type: "secondary", label: "Read", url: "/" },
      { type: "primary", label: "Discord", url: "https://discord.com/channels/960727872887222332/1100801230328045649" }
    ]
  },
  {
    id: 34,
    title: "Meal Size and Timing",
    image: null,
    description: "How the frequency, size, and timing of meals impacts metabolism, energy, and body composition.",
    tags: ["meal-timing", "frequency", "nutrition", "metabolism", "energy", "meal-planning"],
    buttons: [
      { type: "primary", label: "Watch", url: "/" },
      { type: "secondary", label: "Read", url: "/" },
      { type: "primary", label: "Discord", url: "https://discord.com/channels/960727872887222332/1164746973392408586" }
    ]
  },
  {
    id: 35,
    title: "Insulin Resistance: A holistic Approach",
    image: null,
    description: "Understanding and addressing insulin sensitivity through diet, exercise, and lifestyle changes.",
    tags: ["insulin", "metabolism", "health", "blood-sugar", "nutrition", "lifestyle"],
    buttons: [
      { type: "primary", label: "Watch", url: "/" },
      { type: "secondary", label: "Read", url: "/" },
      { type: "primary", label: "Discord", url: "https://discord.com/channels/960727872887222332/1194489521945980968" }
    ]
  },
  {
    id: 36,
    title: "Protein Shakes make my Stomach Upset, What can I do?",
    image: null,
    description: "Solutions for digestive issues related to protein supplements and alternatives to try.",
    tags: ["protein", "digestion", "supplements", "lactose", "alternatives", "gut-health"],
    buttons: [
      { type: "primary", label: "Watch", url: "/" },
      { type: "secondary", label: "Read", url: "/" },
      { type: "primary", label: "Discord", url: "https://discord.com/channels/960727872887222332/1098590026801107055" }
    ]
  },
  {
    id: 37,
    title: "12 Healthier Variations of Sweet Treats",
    image: null,
    description: "Satisfy your sweet tooth with these nutritious alternatives to traditional desserts.",
    tags: ["recipes", "desserts", "healthy", "alternatives", "nutrition", "treats"],
    buttons: [
      { type: "primary", label: "Watch", url: "/" },
      { type: "secondary", label: "Read", url: "/" },
      { type: "primary", label: "Discord", url: "https://discord.com/channels/960727872887222332/1162457459441545226" }
    ]
  },
  {
    id: 38,
    title: "Diet Trouble Shooting",
    image: null,
    description: "Common problems with nutrition plans and practical solutions to get back on track.",
    tags: ["diet", "troubleshooting", "nutrition", "plateaus", "adherence", "solutions"],
    buttons: [
      { type: "primary", label: "Watch", url: "/" },
      { type: "secondary", label: "Read", url: "/" },
      { type: "primary", label: "Discord", url: "https://discord.com/channels/960727872887222332/1164751277654294648" }
    ]
  },
  {
    id: 39,
    title: "30 Grams of Protein Cheat Sheet",
    image: null,
    description: "Quick reference guide for foods that provide approximately 30g of protein per serving.",
    tags: ["protein", "nutrition", "foods", "portions", "meal-planning", "reference"],
    buttons: [
      { type: "primary", label: "Watch", url: "/" },
      { type: "secondary", label: "Read", url: "/" },
      { type: "primary", label: "Discord", url: "https://discord.com/channels/960727872887222332/1226584936622850220" }
    ]
  },
  // WOMEN'S FITNESS
{
  id: 40,
  title: "Girls Section",
  image: null,
  description: "Resources specifically for women's fitness needs, training considerations, and female-specific guidance.",
  tags: ["women", "female", "training", "fitness", "glutes", "hormones"],
  buttons: [
    { type: "primary", label: "Watch", url: "/" },
    { type: "secondary", label: "Read", url: "/" },
    { type: "primary", label: "Discord", url: "https://discord.com/channels/960727872887222332/1229448993130414193" }
  ]
},

// SLEEP RESOURCES
{
  id: 41,
  title: "Sleep Recommendations",
  image: null,
  description: "Guidelines for optimizing sleep quality and duration to support recovery, performance, and overall health.",
  tags: ["sleep", "recovery", "health", "performance", "habits", "rest"],
  buttons: [
    { type: "primary", label: "Watch", url: "/" },
    { type: "secondary", label: "Read", url: "/" },
    { type: "primary", label: "Discord", url: "https://discord.com/channels/960727872887222332/1072340083644043374" }
  ]
},
{
  id: 42,
  title: "Effects of Sleep Deprivation",
  image: null,
  description: "How lack of sleep impacts muscle growth, fat loss, performance, hormones, and overall well-being.",
  tags: ["sleep", "deprivation", "recovery", "hormones", "health", "performance"],
  buttons: [
    { type: "primary", label: "Watch", url: "/" },
    { type: "secondary", label: "Read", url: "/" },
    { type: "primary", label: "Discord", url: "https://discord.com/channels/960727872887222332/1145753555412725891" }
  ]
},
{
  id: 43,
  title: "Taking Naps",
  image: null,
  description: "How to effectively use napping to enhance recovery, energy levels, and cognitive function.",
  tags: ["sleep", "naps", "recovery", "energy", "cognitive", "rest"],
  buttons: [
    { type: "primary", label: "Watch", url: "/" },
    { type: "secondary", label: "Read", url: "/" },
    { type: "primary", label: "Discord", url: "https://discord.com/channels/960727872887222332/1078304014577455164" }
  ]
},

// TRAINING RESOURCES
{
  id: 44,
  title: "Different Types of Sets",
  image: null,
  description: "Explanation of various set techniques like drop sets, super sets, giant sets, and more for training variety.",
  tags: ["training", "sets", "techniques", "intensity", "variety", "programming"],
  buttons: [
    { type: "primary", label: "Watch", url: "/" },
    { type: "secondary", label: "Read", url: "/" },
    { type: "primary", label: "Discord", url: "https://discord.com/channels/960727872887222332/1205519803603750972" }
  ]
},
{
  id: 45,
  title: "How To Train Better In The Gym",
  image: null,
  description: "Practical tips for making your workouts more effective, efficient, and productive.",
  tags: ["training", "technique", "efficiency", "performance", "workout", "improvement"],
  buttons: [
    { type: "primary", label: "Watch", url: "/" },
    { type: "secondary", label: "Read", url: "/" },
    { type: "primary", label: "Discord", url: "https://discord.com/channels/960727872887222332/1176389103969898526" }
  ]
},
{
  id: 46,
  title: "Mind Muscle Connection",
  image: null,
  description: "How to develop and utilize the mind-muscle connection to maximize muscle activation and growth.",
  tags: ["training", "technique", "mind-muscle", "activation", "focus", "neuromuscular"],
  buttons: [
    { type: "primary", label: "Watch", url: "/" },
    { type: "secondary", label: "Read", url: "/" },
    { type: "primary", label: "Discord", url: "https://discord.com/channels/960727872887222332/1162461953592139849" }
  ]
},
{
  id: 47,
  title: "Key Training Terms Explained",
  image: null,
  description: "Definitions and explanations of common training terminology to help you better understand fitness content.",
  tags: ["training", "terminology", "education", "concepts", "fitness", "glossary"],
  buttons: [
    { type: "primary", label: "Watch", url: "/" },
    { type: "secondary", label: "Read", url: "/" },
    { type: "primary", label: "Discord", url: "https://discord.com/channels/960727872887222332/1162365655522230322" }
  ]
},
{
  id: 48,
  title: "Running and Lifting",
  image: null,
  description: "How to effectively combine cardio and resistance training for optimal results without interference.",
  tags: ["cardio", "training", "running", "weightlifting", "combined", "concurrent"],
  buttons: [
    { type: "primary", label: "Watch", url: "/" },
    { type: "secondary", label: "Read", url: "/" },
    { type: "primary", label: "Discord", url: "https://discord.com/channels/960727872887222332/1104758807856435230" }
  ]
},
{
  id: 49,
  title: "Recovery Times During Sets",
  image: null,
  description: "Optimal rest periods between sets for different training goals and how they affect performance.",
  tags: ["training", "recovery", "rest", "performance", "sets", "timing"],
  buttons: [
    { type: "primary", label: "Watch", url: "/" },
    { type: "secondary", label: "Read", url: "/" },
    { type: "primary", label: "Discord", url: "https://discord.com/channels/960727872887222332/1101121931278823486" }
  ]
},
{
  id: 50,
  title: "Best Rep Ranges for Hypertrophy",
  image: null,
  description: "Understanding the optimal number of repetitions for maximizing muscle growth and size gains.",
  tags: ["training", "hypertrophy", "reps", "muscle-growth", "technique", "programming"],
  buttons: [
    { type: "primary", label: "Watch", url: "/" },
    { type: "secondary", label: "Read", url: "/" },
    { type: "primary", label: "Discord", url: "https://discord.com/channels/960727872887222332/1101121676458066091" }
  ]
},
{
  id: 51,
  title: "How Many Sets Should I Do A Week",
  image: null,
  description: "Guidelines for weekly training volume to optimize muscle growth while managing recovery.",
  tags: ["training", "volume", "sets", "programming", "frequency", "recovery"],
  buttons: [
    { type: "primary", label: "Watch", url: "/" },
    { type: "secondary", label: "Read", url: "/" },
    { type: "primary", label: "Discord", url: "https://discord.com/channels/960727872887222332/1072342948261412934" }
  ]
},
{
  id: 52,
  title: "Tips For Growing Glutes",
  image: null,
  description: "Specialized training strategies and exercises for developing stronger, larger glute muscles.",
  tags: ["glutes", "training", "women", "lower-body", "exercises", "technique"],
  buttons: [
    { type: "primary", label: "Watch", url: "/" },
    { type: "secondary", label: "Read", url: "/" },
    { type: "primary", label: "Discord", url: "https://discord.com/channels/960727872887222332/1072692327774158892" }
  ]
},

// RECOVERY RESOURCES
{
  id: 53,
  title: "Fatigue In Depth",
  image: null,
  description: "Understanding the different types of fatigue, their causes, and effective management strategies.",
  tags: ["recovery", "fatigue", "performance", "overtraining", "management", "rest"],
  buttons: [
    { type: "primary", label: "Watch", url: "/" },
    { type: "secondary", label: "Read", url: "/" },
    { type: "primary", label: "Discord", url: "https://discord.com/channels/960727872887222332/1190413348534231141" }
  ]
},
{
  id: 54,
  title: "How To Use A Massage Gun",
  image: null,
  description: "Proper techniques and protocols for using percussion therapy devices to enhance recovery.",
  tags: ["recovery", "massage", "tools", "soreness", "technique", "equipment"],
  buttons: [
    { type: "primary", label: "Watch", url: "/" },
    { type: "secondary", label: "Read", url: "/" },
    { type: "primary", label: "Discord", url: "https://discord.com/channels/960727872887222332/1190408512224890950" }
  ]
},
{
  id: 55,
  title: "Essentials of Staying Hydrated",
  image: null,
  description: "How proper hydration affects performance, recovery, and overall health for athletes.",
  tags: ["hydration", "recovery", "water", "performance", "electrolytes", "health"],
  buttons: [
    { type: "primary", label: "Watch", url: "/" },
    { type: "secondary", label: "Read", url: "/" },
    { type: "primary", label: "Discord", url: "https://discord.com/channels/960727872887222332/1162459781009772635" }
  ]
},
{
  id: 56,
  title: "Injury Prevention: Nutrition and Hydration",
  image: null,
  description: "Learn how proper nutrition and hydration contribute to injury prevention and overall joint health.",
  tags: ["injury-prevention", "nutrition", "hydration", "recovery", "health", "performance"],
  buttons: [
    { type: "primary", label: "Watch", url: "/" },
    { type: "secondary", label: "Read", url: "/" },
    { type: "primary", label: "Discord", url: "https://discord.com/channels/960727872887222332/1169697421064945816" }
  ]
},
{
  id: 57,
  title: "Injury Prevention: Listening to Your Body and Resting When Needed",
  image: null,
  description: "Understanding the importance of rest, recognizing warning signs, and knowing when to back off from training.",
  tags: ["injury-prevention", "recovery", "rest", "listening", "overtraining", "health"],
  buttons: [
    { type: "primary", label: "Watch", url: "/" },
    { type: "secondary", label: "Read", url: "/" },
    { type: "primary", label: "Discord", url: "https://discord.com/channels/960727872887222332/1165096726508621844" }
  ]
},
{
  id: 58,
  title: "Injury Prevention: Balancing Workouts and Muscle Groups",
  image: null,
  description: "How to create balanced training programs that prevent muscle imbalances and reduce injury risk, featuring Project Reign.",
  tags: ["injury-prevention", "balance", "programming", "muscle-groups", "training", "project-reign"],
  buttons: [
    { type: "primary", label: "Watch", url: "/" },
    { type: "secondary", label: "Read", url: "/" },
    { type: "primary", label: "Discord", url: "https://discord.com/channels/960727872887222332/1164323571879907438" }
  ]
},
{
  id: 59,
  title: "Injury Prevention: Prioritizing Mobility and Flexibility",
  image: null,
  description: "The role of mobility and flexibility training in preventing injuries and improving movement quality.",
  tags: ["injury-prevention", "mobility", "flexibility", "movement", "stretching", "range-of-motion"],
  buttons: [
    { type: "primary", label: "Watch", url: "/" },
    { type: "secondary", label: "Read", url: "/" },
    { type: "primary", label: "Discord", url: "https://discord.com/channels/960727872887222332/1164740539413962772" }
  ]
},
{
  id: 60,
  title: "Injury Prevention: Warming Up and Cooling Down",
  image: null,
  description: "Effective warm-up and cool-down protocols to prepare your body for training and enhance recovery, featuring Project Reign.",
  tags: ["injury-prevention", "warm-up", "cool-down", "preparation", "recovery", "project-reign"],
  buttons: [
    { type: "primary", label: "Watch", url: "/" },
    { type: "secondary", label: "Read", url: "/" },
    { type: "primary", label: "Discord", url: "https://discord.com/channels/960727872887222332/1163854270588801107" }
  ]
},
{
  id: 61,
  title: "Injury Prevention: Gradual Progression and Periodization",
  image: null,
  description: "How to safely progress your training over time and structure training cycles to minimize injury risk, featuring Project Reign.",
  tags: ["injury-prevention", "progression", "periodization", "programming", "overload", "project-reign"],
  buttons: [
    { type: "primary", label: "Watch", url: "/" },
    { type: "secondary", label: "Read", url: "/" },
    { type: "primary", label: "Discord", url: "https://discord.com/channels/960727872887222332/1163250602927738970" }
  ]
},
{
  id: 62,
  title: "Injury Prevention: Proper Form and Technique",
  image: null,
  description: "The importance of correct exercise form and technique in preventing injuries and maximizing results, featuring Project Reign.",
  tags: ["injury-prevention", "form", "technique", "execution", "safety", "project-reign"],
  buttons: [
    { type: "primary", label: "Watch", url: "/" },
    { type: "secondary", label: "Read", url: "/" },
    { type: "primary", label: "Discord", url: "https://discord.com/channels/960727872887222332/1162952716369264752" }
  ]
},
{
  id: 63,
  title: "McGill Big 3: Your Possible Lower Back Pain Solution",
  image: null,
  description: "Learn about the McGill Big 3 exercises for core stability and their role in preventing and managing lower back pain.",
  tags: ["injury-prevention", "back-pain", "mcgill", "core", "stability", "rehabilitation"],
  buttons: [
    { type: "primary", label: "Watch", url: "/" },
    { type: "secondary", label: "Read", url: "/" },
    { type: "primary", label: "Discord", url: "https://discord.com/channels/960727872887222332/1215119519782010921" }
  ]
},

// EXERCISE SPECIFICS RESOURCES
{
  id: 64,
  title: "A Comprehensive Guide to Forearm and Grip Strength Training",
  image: null,
  description: "Techniques, exercises, and programming strategies to develop stronger forearms and improve grip strength.",
  tags: ["training", "forearms", "grip-strength", "exercises", "technique", "programming"],
  buttons: [
    { type: "primary", label: "Watch", url: "/" },
    { type: "secondary", label: "Read", url: "/" },
    { type: "primary", label: "Discord", url: "https://discord.com/channels/960727872887222332/1077741500101824613" }
  ]
},
{
  id: 65,
  title: "Building a Better Bench",
  image: null,
  description: "Advanced techniques and form tips to improve your bench press strength, safety, and effectiveness.",
  tags: ["training", "bench-press", "technique", "chest", "strength", "powerlifting"],
  buttons: [
    { type: "primary", label: "Watch", url: "/" },
    { type: "secondary", label: "Read", url: "/" },
    { type: "primary", label: "Discord", url: "https://discord.com/channels/960727872887222332/1106566592806015037" }
  ]
},
{
  id: 66,
  title: "Overhead Press",
  image: null,
  description: "Mastering the overhead press: technique, variations, programming, and troubleshooting common issues.",
  tags: ["training", "overhead-press", "shoulders", "technique", "strength", "compound"],
  buttons: [
    { type: "primary", label: "Watch", url: "/" },
    { type: "secondary", label: "Read", url: "/" },
    { type: "primary", label: "Discord", url: "https://discord.com/channels/960727872887222332/1128160759294201856" }
  ]
},
{
  id: 67,
  title: "Correct Squat Based on Bone Structure",
  image: null,
  description: "How to adjust squat form and stance based on individual anatomy and biomechanics for optimal results and safety.",
  tags: ["training", "squat", "anatomy", "biomechanics", "technique", "personalization"],
  buttons: [
    { type: "primary", label: "Watch", url: "/" },
    { type: "secondary", label: "Read", url: "/" },
    { type: "primary", label: "Discord", url: "https://discord.com/channels/960727872887222332/1128340139895164999" }
  ]
},

// TRAINING INFORMATION RESOURCES
{
  id: 68,
  title: "Training Universals",
  image: null,
  description: "Core principles and concepts that apply across all training styles and goals for consistent progress.",
  tags: ["training", "principles", "fundamentals", "programming", "progress", "consistency"],
  buttons: [
    { type: "primary", label: "Watch", url: "/" },
    { type: "secondary", label: "Read", url: "/" },
    { type: "primary", label: "Discord", url: "https://discord.com/channels/960727872887222332/1143949061221789756" }
  ]
},
{
  id: 69,
  title: "Training to Failure",
  image: null,
  description: "The benefits, risks, and applications of taking sets to muscular failure in your training program.",
  tags: ["training", "failure", "intensity", "recovery", "programming", "technique"],
  buttons: [
    { type: "primary", label: "Watch", url: "/" },
    { type: "secondary", label: "Read", url: "/" },
    { type: "primary", label: "Discord", url: "https://discord.com/channels/960727872887222332/1116760091161526433" }
  ]
},
{
  id: 70,
  title: "Increasing Athleticism",
  image: null,
  description: "Strategies and training methods to improve overall athletic performance, power, speed, and coordination.",
  tags: ["training", "athleticism", "performance", "power", "speed", "coordination"],
  buttons: [
    { type: "primary", label: "Watch", url: "/" },
    { type: "secondary", label: "Read", url: "/" },
    { type: "primary", label: "Discord", url: "https://discord.com/channels/960727872887222332/1072279077605617704" }
  ]
},
{
  id: 71,
  title: "Workout Planning Template",
  image: null,
  description: "A practical framework for designing effective workout programs tailored to your goals and circumstances.",
  tags: ["training", "planning", "template", "programming", "organization", "structure"],
  buttons: [
    { type: "primary", label: "Watch", url: "/" },
    { type: "secondary", label: "Read", url: "/" },
    { type: "primary", label: "Discord", url: "https://discord.com/channels/960727872887222332/1095494016394608750" }
  ]
},
{
  id: 72,
  title: "Hypertrophy 101",
  image: null,
  description: "The fundamental principles and science of muscle growth for beginners and experienced lifters alike.",
  tags: ["hypertrophy", "muscle-growth", "training", "science", "basics", "fundamentals"],
  buttons: [
    { type: "primary", label: "Watch", url: "/" },
    { type: "secondary", label: "Read", url: "/" },
    { type: "primary", label: "Discord", url: "https://discord.com/channels/960727872887222332/1110400478958858322" }
  ]
},
{
  id: 73,
  title: "Does Response of Training Factors Influence Hypertrophy",
  image: null,
  description: "Exploring how individual response to different training variables affects muscle growth outcomes.",
  tags: ["hypertrophy", "response", "individual-differences", "training", "adaptation", "variables"],
  buttons: [
    { type: "primary", label: "Watch", url: "/" },
    { type: "secondary", label: "Read", url: "/" },
    { type: "primary", label: "Discord", url: "https://discord.com/channels/960727872887222332/1164742621252558858" }
  ]
},
{
  id: 74,
  title: "Scientific Principles Of Hypertrophy Training",
  image: null,
  description: "Evidence-based concepts and methodologies for maximizing muscle growth through structured training.",
  tags: ["hypertrophy", "science", "principles", "training", "muscle-growth", "research"],
  buttons: [
    { type: "primary", label: "Watch", url: "/" },
    { type: "secondary", label: "Read", url: "/" },
    { type: "primary", label: "Discord", url: "https://discord.com/channels/960727872887222332/1164740210077208738" }
  ]
},
{
  id: 75,
  title: "Overload Requirements For Hypertrophy",
  image: null,
  description: "Understanding the progressive overload thresholds necessary to stimulate continued muscle growth.",
  tags: ["hypertrophy", "overload", "progression", "stimulus", "muscle-growth", "adaptation"],
  buttons: [
    { type: "primary", label: "Watch", url: "/" },
    { type: "secondary", label: "Read", url: "/" },
    { type: "primary", label: "Discord", url: "https://discord.com/channels/960727872887222332/1162348352302743623" }
  ]
},

// TRAINING VOLUME RESOURCES
{
  id: 76,
  title: "How to Find Your Maximum Adaptive Volume",
  image: null,
  description: "Methods for determining the highest training volume your body can recover from and adapt to.",
  tags: ["training", "volume", "MAV", "recovery", "programming", "adaptation"],
  buttons: [
    { type: "primary", label: "Watch", url: "/" },
    { type: "secondary", label: "Read", url: "/" },
    { type: "primary", label: "Discord", url: "https://discord.com/channels/960727872887222332/1173408412680335361" }
  ]
},
{
  id: 77,
  title: "How to Find Your Minimum Effective Volume",
  image: null,
  description: "Identifying the lowest amount of training volume needed to make progress toward your fitness goals.",
  tags: ["training", "volume", "MEV", "efficiency", "programming", "minimum"],
  buttons: [
    { type: "primary", label: "Watch", url: "/" },
    { type: "secondary", label: "Read", url: "/" },
    { type: "primary", label: "Discord", url: "https://discord.com/channels/960727872887222332/1173408056973992009" }
  ]
},
{
  id: 78,
  title: "How To Find Your Maintenance Volume",
  image: null,
  description: "Guidelines for determining the training volume required to maintain current muscle mass and strength.",
  tags: ["training", "volume", "maintenance", "preservation", "programming", "deload"],
  buttons: [
    { type: "primary", label: "Watch", url: "/" },
    { type: "secondary", label: "Read", url: "/" },
    { type: "primary", label: "Discord", url: "https://discord.com/channels/960727872887222332/1173407540395126824" }
  ]
},

// POSING RESOURCES
{
  id: 79,
  title: "Posing Resource Library",
  image: null,
  description: "Comprehensive guide to bodybuilding poses, techniques, and practice methods for competitions or physique display.",
  tags: ["posing", "bodybuilding", "competition", "physique", "presentation", "aesthetics"],
  buttons: [
    { type: "primary", label: "Watch", url: "/" },
    { type: "secondary", label: "Read", url: "/" },
    { type: "primary", label: "Discord", url: "https://discord.com/channels/960727872887222332/1215123156734574644" }
  ]
},

// STRENGTH TRAINING RESOURCES
{
  id: 80,
  title: "Squat Resources",
  image: null,
  description: "Collection of techniques, programs, and tips specifically for improving squat strength and performance.",
  tags: ["strength", "squats", "powerlifting", "technique", "lower-body", "compound"],
  buttons: [
    { type: "primary", label: "Watch", url: "/" },
    { type: "secondary", label: "Read", url: "/" },
    { type: "primary", label: "Discord", url: "https://discord.com/channels/960727872887222332/1190417412688982216" }
  ]
},
{
  id: 81,
  title: "The Scientific Principles of Strength Training",
  image: null,
  description: "Evidence-based framework for developing maximal strength through structured training methodology.",
  tags: ["strength", "science", "principles", "training", "powerlifting", "research"],
  buttons: [
    { type: "primary", label: "Watch", url: "/" },
    { type: "secondary", label: "Read", url: "/" },
    { type: "primary", label: "Discord", url: "https://discord.com/channels/960727872887222332/1164739899677753414" }
  ]
},
{
  id: 82,
  title: "Overload Requirement For Strength",
  image: null,
  description: "Understanding the progressive loading necessary to continue making strength gains over time.",
  tags: ["strength", "overload", "progression", "resistance", "programming", "adaptation"],
  buttons: [
    { type: "primary", label: "Watch", url: "/" },
    { type: "secondary", label: "Read", url: "/" },
    { type: "primary", label: "Discord", url: "https://discord.com/channels/960727872887222332/1109143679559553064" }
  ]
},
{
  id: 83,
  title: "Things I'd Tell Young Me",
  image: null,
  description: "Valuable insights and lessons learned from years of training experience to help beginners avoid common mistakes.",
  tags: ["advice", "experience", "beginners", "lessons", "training", "mindset"],
  buttons: [
    { type: "primary", label: "Watch", url: "/" },
    { type: "secondary", label: "Read", url: "/" },
    { type: "primary", label: "Discord", url: "https://discord.com/channels/960727872887222332/1159569445979697252" }
  ]
},

// EQUIPMENT AND APPS RESOURCES
{
  id: 84,
  title: "Essential Equipment",
  image: null,
  description: "Must-have tools and gear for effectively pursuing your fitness goals at home or in the gym.",
  tags: ["equipment", "gear", "tools", "essentials", "training", "home-gym"],
  buttons: [
    { type: "primary", label: "Watch", url: "/" },
    { type: "secondary", label: "Read", url: "/" },
    { type: "primary", label: "Discord", url: "https://discord.com/channels/960727872887222332/1158442471626395708" }
  ]
},
{
  id: 85,
  title: "My Helpful But Not Essential Equipment",
  image: null,
  description: "Useful training tools that can enhance your workouts but aren't strictly necessary for progress.",
  tags: ["equipment", "gear", "accessories", "optional", "training", "enhancement"],
  buttons: [
    { type: "primary", label: "Watch", url: "/" },
    { type: "secondary", label: "Read", url: "/" },
    { type: "primary", label: "Discord", url: "https://discord.com/channels/960727872887222332/1158448114936578199" }
  ]
},
{
  id: 86,
  title: "More Apps and Equipment",
  image: null,
  description: "Additional digital tools, applications, and physical equipment to support your fitness journey.",
  tags: ["equipment", "apps", "technology", "tracking", "tools", "fitness-tech"],
  buttons: [
    { type: "primary", label: "Watch", url: "/" },
    { type: "secondary", label: "Read", url: "/" },
    { type: "primary", label: "Discord", url: "https://discord.com/channels/960727872887222332/1158075101787471912" }
  ]
},

// SQUAT TECHNIQUE RESOURCES
{
  id: 87,
  title: "Squat Accessories",
  image: null,
  description: "Supplementary exercises and equipment to improve squat performance and address weaknesses.",
  tags: ["squats", "accessories", "technique", "strength", "lower-body", "assistance"],
  buttons: [
    { type: "primary", label: "Watch", url: "/" },
    { type: "secondary", label: "Read", url: "/" },
    { type: "primary", label: "Discord", url: "https://discord.com/channels/960727872887222332/1142590375056384070" }
  ]
},
{
  id: 88,
  title: "Squat Variations",
  image: null,
  description: "Different types of squats to target specific muscle groups, address weaknesses, or provide training variety.",
  tags: ["squats", "variations", "technique", "lower-body", "strength", "versatility"],
  buttons: [
    { type: "primary", label: "Watch", url: "/" },
    { type: "secondary", label: "Read", url: "/" },
    { type: "primary", label: "Discord", url: "https://discord.com/channels/960727872887222332/1142590281301114984" }
  ]
},
{
  id: 89,
  title: "Squat Technique",
  image: null,
  description: "Proper form, setup, and execution for safe and effective squatting to maximize results and minimize injury risk.",
  tags: ["squats", "technique", "form", "safety", "strength", "lower-body"],
  buttons: [
    { type: "primary", label: "Watch", url: "/" },
    { type: "secondary", label: "Read", url: "/" },
    { type: "primary", label: "Discord", url: "https://discord.com/channels/960727872887222332/1142588968605581393" }
  ]
},

// BENCH PRESS RESOURCES
{
  id: 90,
  title: "Sevourns Bench Workout",
  image: null,
  description: "Specialized bench press program designed to increase strength and performance in the lift.",
  tags: ["bench-press", "program", "strength", "chest", "training", "workout"],
  buttons: [
    { type: "primary", label: "Watch", url: "/" },
    { type: "secondary", label: "Read", url: "/" },
    { type: "primary", label: "Discord", url: "https://discord.com/channels/960727872887222332/1159524312647208960" }
  ]
},
{
  id: 91,
  title: "Sevourns Bench Workout Cont",
  image: null,
  description: "Continuation of the specialized bench press program with additional techniques and programming elements.",
  tags: ["bench-press", "program", "strength", "chest", "training", "advanced"],
  buttons: [
    { type: "primary", label: "Watch", url: "/" },
    { type: "secondary", label: "Read", url: "/" },
    { type: "primary", label: "Discord", url: "https://discord.com/channels/960727872887222332/1159527052635013120" }
  ]
},
{
  id: 92,
  title: "Bench Variations",
  image: null,
  description: "Different types of bench press exercises to target specific areas, address weaknesses, or provide training variety.",
  tags: ["bench-press", "variations", "chest", "strength", "technique", "versatility"],
  buttons: [
    { type: "primary", label: "Watch", url: "/" },
    { type: "secondary", label: "Read", url: "/" },
    { type: "primary", label: "Discord", url: "https://discord.com/channels/960727872887222332/1142589480486834258" }
  ]
},
{
  id: 93,
  title: "Bench Technique",
  image: null,
  description: "Proper form, setup, and execution for safe and effective bench pressing to maximize results.",
  tags: ["bench-press", "technique", "form", "chest", "safety", "strength"],
  buttons: [
    { type: "primary", label: "Watch", url: "/" },
    { type: "secondary", label: "Read", url: "/" },
    { type: "primary", label: "Discord", url: "https://discord.com/channels/960727872887222332/1142587976392646777" }
  ]
},

// DEADLIFT RESOURCES
{
  id: 94,
  title: "Deadlift Variations",
  image: null,
  description: "Different types of deadlift exercises to target specific muscle groups, address weaknesses, or provide training variety.",
  tags: ["deadlift", "variations", "back", "strength", "technique", "versatility"],
  buttons: [
    { type: "primary", label: "Watch", url: "/" },
    { type: "secondary", label: "Read", url: "/" },
    { type: "primary", label: "Discord", url: "https://discord.com/channels/960727872887222332/1142591032169607250" }
  ]
},

// MISCELLANEOUS STRENGTH RESOURCES
{
  id: 95,
  title: "Strength Calculator",
  image: null,
  description: "Tool for estimating maximal strength, comparing lifts, and tracking progress in your strength journey.",
  tags: ["strength", "calculator", "metrics", "tracking", "performance", "progress"],
  buttons: [
    { type: "primary", label: "Watch", url: "/" },
    { type: "secondary", label: "Read", url: "/" },
    { type: "primary", label: "Discord", url: "https://discord.com/channels/960727872887222332/1128341519661809705" }
  ]
},
{
  id: 96,
  title: "The Texas Method",
  image: null,
  description: "Overview of the popular intermediate strength program focusing on weekly progression cycles.",
  tags: ["strength", "program", "texas-method", "intermediate", "powerlifting", "progression"],
  buttons: [
    { type: "primary", label: "Watch", url: "/" },
    { type: "secondary", label: "Read", url: "/" },
    { type: "primary", label: "Discord", url: "https://discord.com/channels/960727872887222332/1121550970506194954" }
  ]
},
{
  id: 97,
  title: "L Sit Trunk Rotation",
  image: null,
  description: "Advanced core exercise combining the L-sit position with trunk rotation for improved core strength and control.",
  tags: ["calisthenics", "core", "l-sit", "rotation", "bodyweight", "advanced"],
  buttons: [
    { type: "primary", label: "Watch", url: "/" },
    { type: "secondary", label: "Read", url: "/" },
    { type: "primary", label: "Discord", url: "https://discord.com/channels/960727872887222332/1110373345511358514" }
  ]
},
{
  id: 98,
  title: "Clutch Flag",
  image: null,
  description: "Guide to achieving the challenging clutch flag position that demonstrates exceptional core and upper body strength.",
  tags: ["calisthenics", "flag", "core", "strength", "bodyweight", "advanced"],
  buttons: [
    { type: "primary", label: "Watch", url: "/" },
    { type: "secondary", label: "Read", url: "/" },
    { type: "primary", label: "Discord", url: "https://discord.com/channels/960727872887222332/1121457230236102767" }
  ]
},
{
  id: 99,
  title: "Dragon Flag",
  image: null,
  description: "Technique and progression for the dragon flag, an advanced core exercise popularized by Bruce Lee.",
  tags: ["calisthenics", "dragon-flag", "core", "strength", "bodyweight", "bruce-lee"],
  buttons: [
    { type: "primary", label: "Watch", url: "/" },
    { type: "secondary", label: "Read", url: "/" },
    { type: "primary", label: "Discord", url: "https://discord.com/channels/960727872887222332/1121456502318846014" }
  ]
},
{
  id: 100,
  title: "Planche (with progression)",
  image: null,
  description: "Step-by-step progression to achieve the planche, an advanced bodyweight hold requiring exceptional strength.",
  tags: ["calisthenics", "planche", "progression", "strength", "bodyweight", "advanced"],
  buttons: [
    { type: "primary", label: "Watch", url: "/" },
    { type: "secondary", label: "Read", url: "/" },
    { type: "primary", label: "Discord", url: "https://discord.com/channels/960727872887222332/1121455734144651325" }
  ]
},
{
  id: 101,
  title: "L Sit (with variations)",
  image: null,
  description: "Complete guide to mastering the L-sit and its variations for improved core strength and body control.",
  tags: ["calisthenics", "l-sit", "core", "variations", "bodyweight", "progression"],
  buttons: [
    { type: "primary", label: "Watch", url: "/" },
    { type: "secondary", label: "Read", url: "/" },
    { type: "primary", label: "Discord", url: "https://discord.com/channels/960727872887222332/1111500033146232883" }
  ]
},
{
  id: 102,
  title: "Front Lever (with progression)",
  image: null,
  description: "Progressive steps to achieve the front lever, an advanced bodyweight hold that builds tremendous back and core strength.",
  tags: ["calisthenics", "front-lever", "back", "core", "progression", "bodyweight"],
  buttons: [
    { type: "primary", label: "Watch", url: "/" },
    { type: "secondary", label: "Read", url: "/" },
    { type: "primary", label: "Discord", url: "https://discord.com/channels/960727872887222332/1111497937516777554" }
  ]
},
{
  id: 103,
  title: "Windshield Wiper (Beginner)",
  image: null,
  description: "Beginner-friendly approach to learning the windshield wiper exercise for oblique and core development.",
  tags: ["calisthenics", "windshield-wiper", "core", "obliques", "beginner", "bodyweight"],
  buttons: [
    { type: "primary", label: "Watch", url: "/" },
    { type: "secondary", label: "Read", url: "/" },
    { type: "primary", label: "Discord", url: "https://discord.com/channels/960727872887222332/1111497546016243712" }
  ]
},
{
  id: 104,
  title: "Windshield Wiper (Int Adv)",
  image: null,
  description: "Intermediate to advanced variations of the windshield wiper for experienced practitioners looking to increase difficulty.",
  tags: ["calisthenics", "windshield-wiper", "core", "obliques", "advanced", "bodyweight"],
  buttons: [
    { type: "primary", label: "Watch", url: "/" },
    { type: "secondary", label: "Read", url: "/" },
    { type: "primary", label: "Discord", url: "https://discord.com/channels/960727872887222332/1111497227584688218" }
  ]
},

// CALISTHENICS ROUTINES AND WARM UPS
{
  id: 105,
  title: "Handstand Routine",
  image: null,
  description: "Structured program for developing and mastering the handstand through consistent, progressive practice.",
  tags: ["calisthenics", "handstand", "routine", "balance", "bodyweight", "progression"],
  buttons: [
    { type: "primary", label: "Watch", url: "/" },
    { type: "secondary", label: "Read", url: "/" },
    { type: "primary", label: "Discord", url: "https://discord.com/channels/960727872887222332/1190405697523957940" }
  ]
},
{
  id: 106,
  title: "Full Body Warm Up",
  image: null,
  description: "Comprehensive warm-up routine to prepare your entire body for calisthenics training and reduce injury risk.",
  tags: ["calisthenics", "warm-up", "mobility", "preparation", "bodyweight", "routine"],
  buttons: [
    { type: "primary", label: "Watch", url: "/" },
    { type: "secondary", label: "Read", url: "/" },
    { type: "primary", label: "Discord", url: "https://discord.com/channels/960727872887222332/1190404897041023006" }
  ]
},

// ADDITIONAL CALISTHENICS RESOURCES
{
  id: 107,
  title: "Recommended YouTube Channels",
  image: null,
  description: "Curated list of the best YouTube channels for learning calisthenics skills, techniques, and training approaches.",
  tags: ["calisthenics", "resources", "youtube", "learning", "education", "channels"],
  buttons: [
    { type: "primary", label: "Watch", url: "/" },
    { type: "secondary", label: "Read", url: "/" },
    { type: "primary", label: "Discord", url: "https://discord.com/channels/960727872887222332/1111499391405137925" }
  ]
},
{
  id: 108,
  title: "Pull Up Progressions",
  image: null,
  description: "Step-by-step progression to build the strength and technique needed for proper pull-ups, from beginner to advanced.",
  tags: ["calisthenics", "pull-ups", "progression", "strength", "back", "bodyweight"],
  buttons: [
    { type: "primary", label: "Watch", url: "/" },
    { type: "secondary", label: "Read", url: "/" },
    { type: "primary", label: "Discord", url: "https://discord.com/channels/960727872887222332/1110394395125100646" }
  ]
},
{
  id: 109,
  title: "Training For Your First Pull Up",
  image: null,
  description: "Specialized program designed to help beginners achieve their first unassisted pull-up.",
  tags: ["calisthenics", "pull-ups", "beginner", "strength", "back", "progression"],
  buttons: [
    { type: "primary", label: "Watch", url: "/" },
    { type: "secondary", label: "Read", url: "/" },
    { type: "primary", label: "Discord", url: "https://discord.com/channels/960727872887222332/1111496955634389002" }
  ]
},
{
  id: 110,
  title: "Muscle Up Tips",
  image: null,
  description: "Technical advice and training strategies to achieve and perfect the challenging muscle-up movement.",
  tags: ["calisthenics", "muscle-up", "technique", "advanced", "upper-body", "progression"],
  buttons: [
    { type: "primary", label: "Watch", url: "/" },
    { type: "secondary", label: "Read", url: "/" },
    { type: "primary", label: "Discord", url: "https://discord.com/channels/960727872887222332/1110375056720269343" }
  ]
},
{
  id: 111,
  title: "More Free Resources I Have Found",
  image: null,
  description: "Collection of additional free resources, tools, and content to support your calisthenics training journey.",
  tags: ["calisthenics", "resources", "free", "training", "education", "tools"],
  buttons: [
    { type: "primary", label: "Watch", url: "/" },
    { type: "secondary", label: "Read", url: "/" },
    { type: "primary", label: "Discord", url: "https://discord.com/channels/960727872887222332/1190422142043574313" }
  ]
},
// MENTAL HEALTH RESOURCES
{
  id: 112,
  title: "Core Mental Fitness Skills",
  image: null,
  description: "Essential practices and techniques for developing mental resilience and psychological well-being.",
  tags: ["mental-health", "resilience", "psychology", "wellness", "mindset", "skills"],
  buttons: [
    { type: "primary", label: "Watch", url: "/" },
    { type: "secondary", label: "Read", url: "/" },
    { type: "primary", label: "Discord", url: "https://discord.com/channels/960727872887222332/1168851469957603369" }
  ]
},
{
  id: 113,
  title: "Mental Health Apps",
  image: null,
  description: "Curated collection of mobile applications designed to support various aspects of mental health and wellness.",
  tags: ["mental-health", "apps", "technology", "self-care", "tools", "support"],
  buttons: [
    { type: "primary", label: "Watch", url: "/" },
    { type: "secondary", label: "Read", url: "/" },
    { type: "primary", label: "Discord", url: "https://discord.com/channels/960727872887222332/1160988393392574524" }
  ]
},
{
  id: 114,
  title: "Dr. K's Guide to Mental Health",
  image: null,
  description: "Comprehensive overview of mental health principles and practices from Dr. K of HealthyGamerGG.",
  tags: ["mental-health", "psychology", "dr-k", "guidance", "wellness", "education"],
  buttons: [
    { type: "primary", label: "Watch", url: "/" },
    { type: "secondary", label: "Read", url: "/" },
    { type: "primary", label: "Discord", url: "https://discord.com/channels/960727872887222332/1138547065471909918" }
  ]
},
{
  id: 115,
  title: "Feeling Overwhelmed",
  image: null,
  description: "Strategies and techniques for managing overwhelming feelings and reducing anxiety in daily life.",
  tags: ["mental-health", "overwhelm", "anxiety", "stress", "coping", "emotional-regulation"],
  buttons: [
    { type: "primary", label: "Watch", url: "/" },
    { type: "secondary", label: "Read", url: "/" },
    { type: "primary", label: "Discord", url: "https://discord.com/channels/960727872887222332/1113660615597228114" }
  ]
},
{
  id: 116,
  title: "Cultivating Lasting Happiness",
  image: null,
  description: "Evidence-based approaches to developing sustainable happiness and contentment beyond temporary pleasure.",
  tags: ["mental-health", "happiness", "well-being", "positive-psychology", "fulfillment", "mindset"],
  buttons: [
    { type: "primary", label: "Watch", url: "/" },
    { type: "secondary", label: "Read", url: "/" },
    { type: "primary", label: "Discord", url: "https://discord.com/channels/960727872887222332/1102972637124235307" }
  ]
},
{
  id: 117,
  title: "Controlling your Emotions",
  image: null,
  description: "Practical methods for emotional regulation and maintaining composure during challenging situations.",
  tags: ["mental-health", "emotions", "regulation", "control", "mindfulness", "psychology"],
  buttons: [
    { type: "primary", label: "Watch", url: "/" },
    { type: "secondary", label: "Read", url: "/" },
    { type: "primary", label: "Discord", url: "https://discord.com/channels/960727872887222332/1100083288376352883" }
  ]
},
{
  id: 118,
  title: "Embracing your Genetics",
  image: null,
  description: "How to accept your genetic predispositions and work with your natural tendencies rather than against them.",
  tags: ["mental-health", "genetics", "acceptance", "body-image", "self-acceptance", "mindset"],
  buttons: [
    { type: "primary", label: "Watch", url: "/" },
    { type: "secondary", label: "Read", url: "/" },
    { type: "primary", label: "Discord", url: "https://discord.com/channels/960727872887222332/1278744573266497607" }
  ]
},

// SELF CARE RESOURCES
{
  id: 119,
  title: "Bob's Beard Resources",
  image: null,
  description: "Comprehensive guide to beard care, maintenance, and grooming for men focused on self-care.",
  tags: ["self-care", "grooming", "beard", "men", "hygiene", "appearance"],
  buttons: [
    { type: "primary", label: "Watch", url: "/" },
    { type: "secondary", label: "Read", url: "/" },
    { type: "primary", label: "Discord", url: "https://discord.com/channels/960727872887222332/1239555705380409424" }
  ]
},

// CHASING DREAMS AND DISCIPLINE RESOURCES
{
  id: 120,
  title: "Chase Your Dreams (Male Perspective)",
  image: null,
  description: "Motivational guidance on pursuing aspirations and goals from a masculine viewpoint.",
  tags: ["motivation", "dreams", "goals", "men", "perspective", "ambition"],
  buttons: [
    { type: "primary", label: "Watch", url: "/" },
    { type: "secondary", label: "Read", url: "/" },
    { type: "primary", label: "Discord", url: "https://discord.com/channels/960727872887222332/1197149833757208677" }
  ]
},
{
  id: 121,
  title: "Achieving Your Goals",
  image: null,
  description: "Strategic approaches to setting and accomplishing meaningful personal and professional goals.",
  tags: ["goals", "achievement", "planning", "success", "productivity", "strategy"],
  buttons: [
    { type: "primary", label: "Watch", url: "/" },
    { type: "secondary", label: "Read", url: "/" },
    { type: "primary", label: "Discord", url: "https://discord.com/channels/960727872887222332/1194007870279262308" }
  ]
},
{
  id: 122,
  title: "Understanding Discipline",
  image: null,
  description: "The psychology behind discipline and how to develop consistent habits for long-term success.",
  tags: ["discipline", "habits", "consistency", "psychology", "willpower", "routine"],
  buttons: [
    { type: "primary", label: "Watch", url: "/" },
    { type: "secondary", label: "Read", url: "/" },
    { type: "primary", label: "Discord", url: "https://discord.com/channels/960727872887222332/1072884006573588531" }
  ]
},
{
  id: 123,
  title: "How to Stay 'Motivated'",
  image: null,
  description: "Sustainable approaches to maintaining drive and enthusiasm beyond fleeting motivation.",
  tags: ["motivation", "discipline", "consistency", "habits", "psychology", "mindset"],
  buttons: [
    { type: "primary", label: "Watch", url: "/" },
    { type: "secondary", label: "Read", url: "/" },
    { type: "primary", label: "Discord", url: "https://discord.com/channels/960727872887222332/1190307072269615104" }
  ]
},

// SELF DISCOVERY AND IMPROVEMENT RESOURCES
{
  id: 124,
  title: "Overcoming Procrastination",
  image: null,
  description: "Practical strategies to break the procrastination cycle and increase productivity.",
  tags: ["productivity", "procrastination", "habits", "time-management", "psychology", "self-improvement"],
  buttons: [
    { type: "primary", label: "Watch", url: "/" },
    { type: "secondary", label: "Read", url: "/" },
    { type: "primary", label: "Discord", url: "https://discord.com/channels/960727872887222332/1101500432439848990" }
  ]
},
{
  id: 125,
  title: "Letting Go of the EGO",
  image: null,
  description: "How to recognize and release ego-driven behaviors for greater personal growth and authentic relationships.",
  tags: ["ego", "mindfulness", "self-awareness", "psychology", "growth", "relationships"],
  buttons: [
    { type: "primary", label: "Watch", url: "/" },
    { type: "secondary", label: "Read", url: "/" },
    { type: "primary", label: "Discord", url: "https://discord.com/channels/960727872887222332/1163985661632720966" }
  ]
},
{
  id: 126,
  title: "Who Are You Really?",
  image: null,
  description: "Exploration of personal identity, authentic self, and discovering your core values and purpose.",
  tags: ["identity", "self-discovery", "authenticity", "purpose", "values", "psychology"],
  buttons: [
    { type: "primary", label: "Watch", url: "/" },
    { type: "secondary", label: "Read", url: "/" },
    { type: "primary", label: "Discord", url: "https://discord.com/channels/960727872887222332/1161833245114105866" }
  ]
},
{
  id: 127,
  title: "Willpower Full Guide",
  image: null,
  description: "Comprehensive exploration of willpower: how it works, how to strengthen it, and how to use it effectively.",
  tags: ["willpower", "self-control", "discipline", "psychology", "habits", "mental-strength"],
  buttons: [
    { type: "primary", label: "Watch", url: "/" },
    { type: "secondary", label: "Read", url: "/" },
    { type: "primary", label: "Discord", url: "https://discord.com/channels/960727872887222332/1161827059295850556" }
  ]
},
{
  id: 128,
  title: "Learn to Plan More Efficiently",
  image: null,
  description: "Effective planning methods to optimize time management and accomplish goals with less stress.",
  tags: ["planning", "productivity", "time-management", "organization", "efficiency", "goals"],
  buttons: [
    { type: "primary", label: "Watch", url: "/" },
    { type: "secondary", label: "Read", url: "/" },
    { type: "primary", label: "Discord", url: "https://discord.com/channels/960727872887222332/1159076668107923585" }
  ]
},
{
  id: 129,
  title: "Resisting Urges",
  image: null,
  description: "Psychological techniques for managing cravings, impulses, and unwanted habitual behaviors.",
  tags: ["self-control", "urges", "impulse-control", "habits", "psychology", "willpower"],
  buttons: [
    { type: "primary", label: "Watch", url: "/" },
    { type: "secondary", label: "Read", url: "/" },
    { type: "primary", label: "Discord", url: "https://discord.com/channels/960727872887222332/1111752947852906506" }
  ]
},
{
  id: 130,
  title: "A Change In Perspective",
  image: null,
  description: "How shifting your viewpoint can transform challenges into opportunities and lead to personal growth.",
  tags: ["perspective", "mindset", "growth", "psychology", "attitude", "reframing"],
  buttons: [
    { type: "primary", label: "Watch", url: "/" },
    { type: "secondary", label: "Read", url: "/" },
    { type: "primary", label: "Discord", url: "https://discord.com/channels/960727872887222332/1100080335951249518" }
  ]
},
{
  id: 131,
  title: "Breakups and Betrayal",
  image: null,
  description: "Guidance for healing and moving forward after experiencing relationship loss or betrayal.",
  tags: ["relationships", "breakups", "healing", "betrayal", "emotional-health", "recovery"],
  buttons: [
    { type: "primary", label: "Watch", url: "/" },
    { type: "secondary", label: "Read", url: "/" },
    { type: "primary", label: "Discord", url: "https://discord.com/channels/960727872887222332/1100081661758156872" }
  ]
},
{
  id: 132,
  title: "How to Lose Gracefully",
  image: null,
  description: "Developing resilience and maintaining dignity when facing setbacks, defeats, or failures.",
  tags: ["resilience", "failure", "mindset", "sportsmanship", "growth", "character"],
  buttons: [
    { type: "primary", label: "Watch", url: "/" },
    { type: "secondary", label: "Read", url: "/" },
    { type: "primary", label: "Discord", url: "https://discord.com/channels/960727872887222332/1086631444912283719" }
  ]
},

// EDUCATIONAL RESOURCES
{
  id: 133,
  title: "Community Created Book List",
  image: null,
  description: "Curated collection of recommended books on personal development, psychology, and self-improvement.",
  tags: ["books", "reading", "education", "resources", "self-improvement", "learning"],
  buttons: [
    { type: "primary", label: "Watch", url: "/" },
    { type: "secondary", label: "Read", url: "/" },
    { type: "primary", label: "Discord", url: "https://discord.com/channels/960727872887222332/1112876237623406632" }
  ]
},
{
  id: 134,
  title: "Community Created Podcast List",
  image: null,
  description: "Member-recommended podcasts covering topics like fitness, mental health, productivity, and personal growth.",
  tags: ["podcasts", "audio", "education", "resources", "self-improvement", "learning"],
  buttons: [
    { type: "primary", label: "Watch", url: "/" },
    { type: "secondary", label: "Read", url: "/" },
    { type: "primary", label: "Discord", url: "https://discord.com/channels/960727872887222332/1158442886233342063" }
  ]
},

// OTHER RESOURCES
{
  id: 135,
  title: "The Craving Mind",
  image: null,
  description: "Understanding the psychology of cravings, addictions, and how mindfulness can help manage them.",
  tags: ["cravings", "addiction", "mindfulness", "psychology", "habits", "mental-health"],
  buttons: [
    { type: "primary", label: "Watch", url: "/" },
    { type: "secondary", label: "Read", url: "/" },
    { type: "primary", label: "Discord", url: "https://discord.com/channels/960727872887222332/1214911803998605353" }
  ]
},
{
  id: 136,
  title: "Key Predictors of Being Liked in First Encounters",
  image: null,
  description: "Fascinating research findings on the factors that influence likability in initial social interactions.",
  tags: ["social-skills", "likability", "research", "psychology", "relationships", "first-impressions"],
  buttons: [
    { type: "primary", label: "Watch", url: "/" },
    { type: "secondary", label: "Read", url: "/" },
    { type: "primary", label: "Discord", url: "https://discord.com/channels/960727872887222332/1175810685524004875" }
  ]
},
{
  id: 137,
  title: "Making Friends (as an adult)",
  image: null,
  description: "Practical strategies for forming new friendships and social connections in adulthood.",
  tags: ["friendships", "social-skills", "relationships", "adults", "connection", "community"],
  buttons: [
    { type: "primary", label: "Watch", url: "/" },
    { type: "secondary", label: "Read", url: "/" },
    { type: "primary", label: "Discord", url: "https://discord.com/channels/960727872887222332/1167433782241017986" }
  ]
},
{
  id: 138,
  title: "Guided Meditation",
  image: null,
  description: "Collection of accessible meditation practices to reduce stress and increase mindfulness.",
  tags: ["meditation", "mindfulness", "stress-reduction", "mental-health", "relaxation", "practice"],
  buttons: [
    { type: "primary", label: "Watch", url: "/" },
    { type: "secondary", label: "Read", url: "/" },
    { type: "primary", label: "Discord", url: "https://discord.com/channels/960727872887222332/1163977906230399116" }
  ]
},
{
  id: 139,
  title: "Yoga For Beginners",
  image: null,
  description: "Introductory yoga practices accessible for newcomers to improve flexibility, mobility, and mindfulness.",
  tags: ["yoga", "beginners", "mobility", "flexibility", "mindfulness", "fitness"],
  buttons: [
    { type: "primary", label: "Watch", url: "/" },
    { type: "secondary", label: "Read", url: "/" },
    { type: "primary", label: "Discord", url: "https://discord.com/channels/960727872887222332/1162044604229636136" }
  ]
},
{
  id: 140,
  title: "To Love Makes One Solitary",
  image: null,
  description: "Philosophical exploration of love, connection, and the paradoxical nature of intimate relationships.",
  tags: ["love", "philosophy", "relationships", "solitude", "connection", "psychology"],
  buttons: [
    { type: "primary", label: "Watch", url: "/" },
    { type: "secondary", label: "Read", url: "/" },
    { type: "primary", label: "Discord", url: "https://discord.com/channels/960727872887222332/1118570371793309759" }
  ]
},
{
  id: 141,
  title: "Great List of Educational YouTube Channels",
  image: null,
  description: "Curated collection of YouTube channels that offer high-quality educational content across various topics.",
  tags: ["education", "youtube", "learning", "resources", "video", "knowledge"],
  buttons: [
    { type: "primary", label: "Watch", url: "/" },
    { type: "secondary", label: "Read", url: "/" },
    { type: "primary", label: "Discord", url: "https://discord.com/channels/960727872887222332/1260270677705887834" }
  ]
},
{
  id: 142,
  title: "START HERE for Flexibility",
  image: null,
  description: "Essential beginner guide for anyone looking to improve their flexibility and range of motion.",
  tags: ["flexibility", "mobility", "beginners", "stretching", "range-of-motion", "guide"],
  buttons: [
    { type: "primary", label: "Watch", url: "/" },
    { type: "secondary", label: "Read", url: "/" },
    { type: "primary", label: "Discord", url: "https://discord.com/channels/960727872887222332/1190406083215364116" }
  ]
},
{
  id: 143,
  title: "Flexibility Exercises",
  image: null,
  description: "Collection of effective exercises to improve flexibility throughout the entire body.",
  tags: ["flexibility", "exercises", "stretching", "mobility", "range-of-motion", "routine"],
  buttons: [
    { type: "primary", label: "Watch", url: "/" },
    { type: "secondary", label: "Read", url: "/" },
    { type: "primary", label: "Discord", url: "https://discord.com/channels/960727872887222332/1109111136072257546" }
  ]
},
{
  id: 144,
  title: "Flexibility Training",
  image: null,
  description: "Structured approach to flexibility training with progressive methods for long-term improvement.",
  tags: ["flexibility", "training", "progression", "stretching", "mobility", "programming"],
  buttons: [
    { type: "primary", label: "Watch", url: "/" },
    { type: "secondary", label: "Read", url: "/" },
    { type: "primary", label: "Discord", url: "https://discord.com/channels/960727872887222332/1109111607256158269" }
  ]
},
{
  id: 145,
  title: "Shoulder Flexibility",
  image: null,
  description: "Targeted stretches and exercises to improve shoulder mobility and range of motion.",
  tags: ["flexibility", "shoulders", "mobility", "stretching", "upper-body", "range-of-motion"],
  buttons: [
    { type: "primary", label: "Watch", url: "/" },
    { type: "secondary", label: "Read", url: "/" },
    { type: "primary", label: "Discord", url: "https://discord.com/channels/960727872887222332/1190406891847831552" }
  ]
},
{
  id: 146,
  title: "Hamstring Flexibility",
  image: null,
  description: "Effective techniques for improving hamstring flexibility and reducing tightness in the posterior chain.",
  tags: ["flexibility", "hamstrings", "stretching", "posterior-chain", "lower-body", "mobility"],
  buttons: [
    { type: "primary", label: "Watch", url: "/" },
    { type: "secondary", label: "Read", url: "/" },
    { type: "primary", label: "Discord", url: "https://discord.com/channels/960727872887222332/1190406661328879636" }
  ]
},
{
  id: 147,
  title: "BJJ Flexibility Routine",
  image: null,
  description: "Specialized flexibility program designed for Brazilian Jiu-Jitsu practitioners to improve performance and prevent injuries.",
  tags: ["flexibility", "bjj", "martial-arts", "stretching", "mobility", "routine"],
  buttons: [
    { type: "primary", label: "Watch", url: "/" },
    { type: "secondary", label: "Read", url: "/" },
    { type: "primary", label: "Discord", url: "https://discord.com/channels/960727872887222332/1190406304905310249" }
  ]
},
{
  id: 148,
  title: "Hip Flexor Flexibility Routine",
  image: null,
  description: "Targeted exercises to release tight hip flexors and improve pelvic mobility.",
  tags: ["flexibility", "hip-flexors", "hips", "stretching", "mobility", "routine"],
  buttons: [
    { type: "primary", label: "Watch", url: "/" },
    { type: "secondary", label: "Read", url: "/" },
    { type: "primary", label: "Discord", url: "https://discord.com/channels/960727872887222332/1190404707848560844" }
  ]
},
{
  id: 149,
  title: "Beginner Stretch and Flexibility",
  image: null,
  description: "Accessible stretching routine for beginners looking to start their flexibility journey.",
  tags: ["flexibility", "beginners", "stretching", "mobility", "basics", "routine"],
  buttons: [
    { type: "primary", label: "Watch", url: "/" },
    { type: "secondary", label: "Read", url: "/" },
    { type: "primary", label: "Discord", url: "https://discord.com/channels/960727872887222332/1190403470285611138" }
  ]
},
{
  id: 150,
  title: "Upper Body Flexibility Routine",
  image: null,
  description: "Comprehensive routine focusing on improving flexibility in the shoulders, chest, back, and arms.",
  tags: ["flexibility", "upper-body", "stretching", "mobility", "routine", "shoulders"],
  buttons: [
    { type: "primary", label: "Watch", url: "/" },
    { type: "secondary", label: "Read", url: "/" },
    { type: "primary", label: "Discord", url: "https://discord.com/channels/960727872887222332/1190403886608035910" }
  ]
},
{
  id: 151,
  title: "Full Body Flexibility Routine",
  image: null,
  description: "Complete head-to-toe flexibility program to improve overall range of motion and reduce muscle tightness.",
  tags: ["flexibility", "full-body", "stretching", "mobility", "routine", "range-of-motion"],
  buttons: [
    { type: "primary", label: "Watch", url: "/" },
    { type: "secondary", label: "Read", url: "/" },
    { type: "primary", label: "Discord", url: "https://discord.com/channels/960727872887222332/1190402463833018579" }
  ]
},

// MOBILITY RESOURCES
{
  id: 152,
  title: "Full Body Mobility Routine",
  image: null,
  description: "Comprehensive movement-based routine to improve functional mobility throughout the entire body.",
  tags: ["mobility", "full-body", "movement", "function", "routine", "range-of-motion"],
  buttons: [
    { type: "primary", label: "Watch", url: "/" },
    { type: "secondary", label: "Read", url: "/" },
    { type: "primary", label: "Discord", url: "https://discord.com/channels/960727872887222332/1190404536033099840" }
  ]
},
{
  id: 153,
  title: "Hip Mobility Routine",
  image: null,
  description: "Targeted sequence to improve hip function, reduce stiffness, and enhance athletic performance.",
  tags: ["mobility", "hips", "movement", "function", "routine", "lower-body"],
  buttons: [
    { type: "primary", label: "Watch", url: "/" },
    { type: "secondary", label: "Read", url: "/" },
    { type: "primary", label: "Discord", url: "https://discord.com/channels/960727872887222332/1190402949625696438" }
  ]
},
{
  id: 154,
  title: "Hip Mobility",
  image: null,
  description: "Fundamental concepts and exercises for improving hip mobility and function in all planes of movement.",
  tags: ["mobility", "hips", "movement", "function", "lower-body", "exercises"],
  buttons: [
    { type: "primary", label: "Watch", url: "/" },
    { type: "secondary", label: "Read", url: "/" },
    { type: "primary", label: "Discord", url: "https://discord.com/channels/960727872887222332/1167168368944631938" }
  ]
},

// YOGA RESOURCES
{
  id: 155,
  title: "Full Body Stretch Yoga Routine",
  image: null,
  description: "Yoga-based stretching sequence to improve whole-body flexibility and promote relaxation.",
  tags: ["yoga", "stretching", "flexibility", "full-body", "routine", "relaxation"],
  buttons: [
    { type: "primary", label: "Watch", url: "/" },
    { type: "secondary", label: "Read", url: "/" },
    { type: "primary", label: "Discord", url: "https://discord.com/channels/960727872887222332/1190408000389779598" }
  ]
},
{
  id: 156,
  title: "One Stop Shop Yoga",
  image: null,
  description: "Comprehensive yoga resource covering poses, sequences, and practices for all levels and purposes.",
  tags: ["yoga", "flexibility", "mobility", "mindfulness", "practice", "comprehensive"],
  buttons: [
    { type: "primary", label: "Watch", url: "/" },
    { type: "secondary", label: "Read", url: "/" },
    { type: "primary", label: "Discord", url: "https://discord.com/channels/960727872887222332/1190407344627122288" }
  ]
},

// INJURIES AND PAIN RESOURCES
{
  id: 157,
  title: "Shoulder Health Essentials",
  image: null,
  description: "Critical exercises and techniques for maintaining healthy shoulders and preventing common injuries.",
  tags: ["injury-prevention", "shoulders", "rehabilitation", "mobility", "health", "pain-management"],
  buttons: [
    { type: "primary", label: "Watch", url: "/" },
    { type: "secondary", label: "Read", url: "/" },
    { type: "primary", label: "Discord", url: "https://discord.com/channels/960727872887222332/1190415354057457797" }
  ]
},
{
  id: 158,
  title: "Elbow and Wrist Pain",
  image: null,
  description: "Strategies for addressing, preventing, and managing pain in the forearms, elbows, and wrists.",
  tags: ["injury-prevention", "elbow", "wrist", "pain", "rehabilitation", "forearm"],
  buttons: [
    { type: "primary", label: "Watch", url: "/" },
    { type: "secondary", label: "Read", url: "/" },
    { type: "primary", label: "Discord", url: "https://discord.com/channels/960727872887222332/1162349247245582366" }
  ]
},
{
  id: 159,
  title: "Golfer's Elbow",
  image: null,
  description: "Specific techniques for treating and preventing medial epicondylitis (golfer's elbow) pain and inflammation.",
  tags: ["injury-prevention", "elbow", "golfers-elbow", "pain", "rehabilitation", "medial-epicondylitis"],
  buttons: [
    { type: "primary", label: "Watch", url: "/" },
    { type: "secondary", label: "Read", url: "/" },
    { type: "primary", label: "Discord", url: "https://discord.com/channels/960727872887222332/1162045608073383976" }
  ]
},
{
  id: 160,
  title: "Tennis Elbow",
  image: null,
  description: "Effective approaches for treating and preventing lateral epicondylitis (tennis elbow) pain and inflammation.",
  tags: ["injury-prevention", "elbow", "tennis-elbow", "pain", "rehabilitation", "lateral-epicondylitis"],
  buttons: [
    { type: "primary", label: "Watch", url: "/" },
    { type: "secondary", label: "Read", url: "/" },
    { type: "primary", label: "Discord", url: "https://discord.com/channels/960727872887222332/1162045972478701679" }
  ]
},

// FASCIA RESOURCES
{
  id: 161,
  title: "What is Fascia",
  image: null,
  description: "Educational overview of fascia, its role in the body, and why it matters for movement and performance.",
  tags: ["fascia", "anatomy", "connective-tissue", "mobility", "recovery", "education"],
  buttons: [
    { type: "primary", label: "Watch", url: "/" },
    { type: "secondary", label: "Read", url: "/" },
    { type: "primary", label: "Discord", url: "https://discord.com/channels/960727872887222332/1212499910860079164" }
  ]
},
{
  id: 162,
  title: "Self Myofascial Release",
  image: null,
  description: "Techniques using foam rollers, balls, and other tools to release fascial restrictions and improve tissue quality.",
  tags: ["fascia", "myofascial-release", "foam-rolling", "recovery", "mobility", "soft-tissue"],
  buttons: [
    { type: "primary", label: "Watch", url: "/" },
    { type: "secondary", label: "Read", url: "/" },
    { type: "primary", label: "Discord", url: "https://discord.com/channels/960727872887222332/1109112033514889288" }
  ]
},

// STRETCHING RESOURCES
{
  id: 163,
  title: "Static vs Dynamic Stretching",
  image: null,
  description: "Comparison of different stretching methods, their benefits, and when to use each for optimal results.",
  tags: ["stretching", "flexibility", "static-stretching", "dynamic-stretching", "mobility", "warm-up"],
  buttons: [
    { type: "primary", label: "Watch", url: "/" },
    { type: "secondary", label: "Read", url: "/" },
    { type: "primary", label: "Discord", url: "https://discord.com/channels/960727872887222332/1109111788630442094" }
  ]
},
];

function Dir() {
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredContent, setFilteredContent] = useState(contentData);
  const [activeCategory, setActiveCategory] = useState("all");

// Updated categories array with Mental Health and Personal Development
const categories = [
  { id: "all", name: "All Resources" },
  { id: "supplements", name: "Supplements" },
  { id: "diet", name: "Diet & Nutrition" },
  { id: "training", name: "Training" },
  { id: "calisthenics", name: "Calisthenics" },
  { id: "hypertrophy", name: "Hypertrophy" },
  { id: "strength", name: "Strength" },
  { id: "mental-health", name: "Mental Health" },
  { id: "personal-development", name: "Personal Development" },
  { id: "exercise-technique", name: "Exercise Technique" },
  { id: "injury-prevention", name: "Injury Prevention" },
  { id: "equipment", name: "Equipment & Apps" },
  { id: "sleep", name: "Sleep" },
  { id: "recovery", name: "Recovery" },
  { id: "women", name: "Women's Fitness" }
];

// Updated filtering logic to include flexibility and mobility
useEffect(() => {
  let filtered = contentData;
  
  // Filter by category first
  if (activeCategory !== "all") {
    filtered = filtered.filter(item => 
      item.tags.includes(activeCategory) || 
      // For broader categories
      (activeCategory === "supplements" && item.tags.some(tag => 
        ["supplements", "vitamin", "mineral", "protein", "creatine", "pre-workout"].includes(tag))) ||
      (activeCategory === "diet" && item.tags.some(tag => 
        ["nutrition", "diet", "calories", "macros", "meal", "food", "eating", "protein"].includes(tag))) ||
      (activeCategory === "training" && item.tags.some(tag => 
        ["training", "workout", "volume", "program", "exercise", "recovery"].includes(tag))) ||
      (activeCategory === "calisthenics" && item.tags.some(tag => 
        ["calisthenics", "bodyweight", "pull-ups", "handstand", "planche", "front-lever", "muscle-up"].includes(tag))) ||
      (activeCategory === "flexibility" && item.tags.some(tag => 
        ["flexibility", "mobility", "stretching", "yoga", "range-of-motion", "fascia"].includes(tag))) ||
      (activeCategory === "hypertrophy" && item.tags.some(tag => 
        ["hypertrophy", "muscle-growth", "volume", "adaptation", "progressive"].includes(tag))) ||
      (activeCategory === "strength" && item.tags.some(tag => 
        ["strength", "powerlifting", "squat", "bench-press", "deadlift", "texas-method"].includes(tag))) ||
      (activeCategory === "mental-health" && item.tags.some(tag => 
        ["mental-health", "psychology", "emotions", "stress", "anxiety", "mindfulness", "meditation"].includes(tag))) ||
      (activeCategory === "personal-development" && item.tags.some(tag => 
        ["discipline", "goals", "habits", "productivity", "self-improvement", "motivation", "willpower"].includes(tag))) ||
      (activeCategory === "exercise-technique" && item.tags.some(tag => 
        ["technique", "form", "execution", "variations", "accessories"].includes(tag))) ||
      (activeCategory === "injury-prevention" && item.tags.some(tag => 
        ["injury-prevention", "rehabilitation", "safety", "form", "technique"].includes(tag))) ||
      (activeCategory === "equipment" && item.tags.some(tag => 
        ["equipment", "gear", "apps", "tools", "technology"].includes(tag)))
    );
  }
  
  // Then apply search filter if there's a search term
  if (searchTerm) {
    const searchLower = searchTerm.toLowerCase();
    filtered = filtered.filter(item => 
      item.title.toLowerCase().includes(searchLower) ||
      item.description.toLowerCase().includes(searchLower) ||
      item.tags.some(tag => tag.toLowerCase().includes(searchLower))
    );
  }
  
  setFilteredContent(filtered);
}, [searchTerm, activeCategory]);

  // Get image based on reference or use null
  const getImage = (imageRef) => {
    if (imageRef === "caldeficit") return caldeficit;
    return null;
  };

  return (
    <>
      {/* Optional spacing at the top */}
      <Break />

      {/* Wrap everything in a container to ensure consistent bg color */}
      <div className="bg-dark text-white">
        {/* Make the entire main area dark with white text */}
        <main role="main" className="bg-dark text-white">
          {/* Jumbotron */}
          <section className="jumbotron text-center bg-dark text-white mb-0">
            <div className="container">
              <h1>The Gym Community Resources</h1>
              <p className="lead text-white">
                Browse our collection of fitness resources to help you on your journey.
                Search by topic or tag to find exactly what you need.
              </p>
              
              {/* Category tabs */}
              <div className="nav nav-pills justify-content-center mb-4">
                {categories.map(category => (
                  <button
                    key={category.id}
                    className={`nav-link mx-1 ${activeCategory === category.id ? 'active bg-primary' : 'text-white'}`}
                    onClick={() => setActiveCategory(category.id)}
                  >
                    {category.name}
                  </button>
                ))}
              </div>
              
              {/* Search bar */}
              <div className="input-group mb-3 w-75 mx-auto">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search for content by title, description or tags..."
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
                <div className="input-group-append">
                  <button className="btn btn-primary" type="button">
                    Search
                  </button>
                </div>
              </div>
              
              <p>
                <a
                  href="https://www.youtube.com/@TheGymCommunitygg"
                  className="btn btn-primary my-2"
                >
                  Youtube
                </a>
                <a
                  href="https://medium.com/@ogbroly"
                  className="btn btn-secondary my-2"
                  style={{ marginLeft: "10px" }}
                >
                  Medium
                </a>
                <a
                  href="https://www.discord.gg/gymcommunity"
                  className="btn btn-primary my-2"
                  style={{ marginLeft: "10px" }}
                >
                  Discord
                </a>
              </p>
            </div>
          </section>

          {/* Album area */}
          <div className="album py-5 bg-dark text-white">
            <div className="container">
              {filteredContent.length === 0 ? (
                <div className="text-center my-5">
                  <h3>No resources found matching your search.</h3>
                  <p>Try adjusting your search terms or browse by category.</p>
                </div>
              ) : (
                <div className="row">
                  {filteredContent.map((item) => (
                    <div className="col-md-4" key={item.id}>
                      <div className="card mb-4 shadow-sm bg-dark text-white">
                        {item.image ? (
                          <img
                            src={getImage(item.image)}
                            alt={item.title}
                            className="bd-placeholder-img card-img-top"
                            width="100%"
                            height="225"
                          />
                        ) : (
                          <svg
                            className="bd-placeholder-img card-img-top"
                            width="100%"
                            height="225"
                            xmlns="http://www.w3.org/2000/svg"
                            preserveAspectRatio="xMidYMid slice"
                            focusable="false"
                            role="img"
                            aria-label={`Placeholder: ${item.title}`}
                          >
                            <title>{item.title}</title>
                            <rect width="100%" height="100%" fill="#212529" />
                            <text
                              x="50%"
                              y="50%"
                              fill="#eceeef"
                              dy=".3em"
                              textAnchor="middle"
                              fontSize="16"
                              textLength="80%"
                            >
                              {item.title}
                            </text>
                          </svg>
                        )}
                        <div className="card-body">
                          {/* Tags */}
                          <div className="mb-2 tag-container" style={{ maxHeight: "70px", overflowY: "auto" }}>
                            {item.tags.map((tag, index) => (
                              <span 
                                key={index} 
                                className="badge badge-pill badge-primary mr-1 mb-1"
                                style={{ cursor: "pointer" }}
                                onClick={() => setSearchTerm(tag)}
                              >
                                {tag}
                              </span>
                            ))}
                          </div>
                          
                          <p className="card-text">
                            {item.description}
                          </p>
                          <div className="d-flex justify-content-between align-items-center">
                            <div className="btn-group">
                              {item.buttons.map((button, index) => (
                                <button
                                  key={index}
                                  type="button"
                                  className={`btn btn-${button.type} my-2`}
                                  onClick={() => window.open(button.url, "_blank")}
                                  disabled={button.url === "/" && button.label !== "Discord"}
                                >
                                  {button.label}
                                </button>
                              ))}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
        </main>

        {/* Footer, also dark */}
        <footer className="text-white bg-dark py-3">
          <div className="container">
            <p className="float-right mb-0">
              <a href="#top" className="text-white">
                Back to top
              </a>
            </p>
          </div>
        </footer>
      </div>

      {/* Particle effect at the bottom */}
      <Particle />
    </>
  );
}

export default Dir;

