import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Particle from "../Particle";
import Type from "./Type";

function Home() {
  return (
    <>
      {/* 1) Particle on top, not inside a single section */}
      <Particle />

      {/* 2) FIRST SECTION (original Home) */}
      <section className="home-section" id="home">
        <Container className="home-content">
          <Row>
            <Col md={7} className="home-header">
              <h1 style={{ paddingBottom: 15 }} className="heading">
                Hi There!{" "}
                <span className="wave" role="img" aria-labelledby="wave">
                  👋🏻
                </span>
              </h1>

              <h1 className="heading-name">
                Welcome to{" "}
                <strong className="main-name"> The Gym Community Website</strong> 
                We Help Our Members{" "}
              </h1>

              <div style={{ padding: 50, textAlign: "left" }}>
                <Type />
              </div>
            </Col>

            {/* <Col md={5} style={{ paddingBottom: 20 }}>
              <img
                src={homeLogo}
                alt="dumbbell"
                className="img-fluid"
                style={{ maxHeight: "450px" }}
              />
            </Col> */}
          </Row>
        </Container>
      </section>

      {/* 3) Remove excessive <br/> lines and rely on CSS spacing instead */}

      {/* 4) SECOND SECTION (merged Home2) */}
      <section className="home-about-section" id="about">
        <Container>
          <Row>
            <Col md={8} className="home-about-description">
              <h1 style={{ fontSize: "2.6em" }}>
              So what makes <span className="main-name">The Gym Community</span> different?
              </h1>
              <p className="home-about-body">
                  We are founded on education and community.
                <br />
                <br />
                  Our goals are to{" "}
                <i>
                  <b className="main-name">
                    help others, educate, and create a welcoming environment.
                  </b>
                </i>
                <br />
                <br />
                  We have helped our members &nbsp;
                <i>
                  <b className="main-name">Gain Muscle</b>, 
                  <b className="main-name"> Fix Their Form</b>, and{" "}
                  <b className="main-name">Lose Fat.</b>
                </i>
                <br />
                <br />
                  We have coaches available who are knowledgeable in 
                <i><b className="main-name"> dieting</b></i>,
                <i>
                  <b className="main-name"> bodybuilding</b>
                </i>,
                &nbsp;and
                <i>
                  <b className="main-name"> powerlifting</b>
                </i>
                <br />
                <br />
                  Join Today and  &nbsp;
                <i>
                  <b className="main-name">Get Real Time Advice!</b>, 
                </i>
              </p>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
}

export default Home;
