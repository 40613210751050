import React, { useState, useEffect } from "react";
import Footer from "./components/Footer";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate
} from "react-router-dom";
import ScrollToTop from "./components/ScrollToTop";
import "./style.css";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Preloader from "../src/components/Pre";
import Navbar from "./components/Navbar";
import Home from "./components/Home/Home";
import Dir from "./components/Library/Content/Dir";
import { Helmet } from "react-helmet"; // Import React Helmet
import logo from "./Assets/logo.png"; // Import your logo

function App() {
  const [load, upadateLoad] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      upadateLoad(false);
    }, 1200);

    return () => clearTimeout(timer);
  }, []);

  return (
    <Router>
      <Helmet>
        <link rel="icon" href={logo} />

        {/* Apple Touch Icon (for iOS devices, larger) */}
        <link rel="apple-touch-icon" href={logo} />

        {/* Add multiple sizes for different devices */}
        <link rel="icon" type="image/png" sizes="16x16" href={logo} />
        <link rel="icon" type="image/png" sizes="32x32" href={logo} />
        <link rel="icon" type="image/png" sizes="48x48" href={logo} />
        <link rel="icon" type="image/png" sizes="192x192" href={logo} />
        <link rel="icon" type="image/png" sizes="512x512" href={logo} />

        <title>The Gym Community</title>
        <meta name="description" content="The fastest growing fitness library" />
      </Helmet>
      <Preloader load={load} />
      <div className="App" id={load ? "no-scroll" : "scroll"}>
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/dir" element={<Dir />} />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>

        <Footer />
      </div>
      <ScrollToTop />
    </Router>
  );
}

export default App;