import React, { useState, useEffect } from "react";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import Container from "react-bootstrap/Container";
import Dropdown from "react-bootstrap/Dropdown";
import { Link } from "react-router-dom";

// Import relevant icons
import { AiOutlineHome, AiFillYoutube } from "react-icons/ai";
import { FaDiscord, FaDumbbell, FaStore, FaBookOpen } from "react-icons/fa";
import { BiLink, BiDollarCircle } from "react-icons/bi";
import { MdFitnessCenter, MdLibraryBooks } from "react-icons/md";
import { GiGymBag } from "react-icons/gi";
import { SiPatreon, SiMedium } from "react-icons/si";

// Import your logo - replace with your actual logo path
import logo from "../Assets/logo.png"; // Update this path to your actual logo file

function NavBar() {
  const [expand, updateExpanded] = useState(false);
  const [navColour, updateNavbar] = useState(false);

  // Navigation items configuration
  const navItems = [
    {
      type: "link",
      title: "Home",
      path: "/",
      icon: <AiOutlineHome className="nav-icon" />,
      internal: true
    },
    {
      type: "link",
      title: "Resources",
      path: "/dir",
      icon: <MdLibraryBooks className="nav-icon" />,
      internal: true
    },
    {
      type: "link",
      title: "Exercise Library",
      path: "https://www.youtube.com/playlist?list=PLKf51H1fQ15jFBKCi31wyMid0rCKb5SxQ",
      icon: <FaDumbbell className="nav-icon" />,
      internal: false
    },
    {
      type: "link",
      title: "Discord",
      path: "https://www.discord.gg/gymcommunity",
      icon: <FaDiscord className="nav-icon" />,
      internal: false
    },
    {
      type: "link",
      title: "Programs",
      path: "https://www.thegymcommunity.store",
      icon: <MdFitnessCenter className="nav-icon" />,
      internal: false
    },
    {
      type: "dropdown",
      title: "More",
      icon: <BiLink className="nav-icon" />,
      items: [
        {
          title: "Patreon",
          path: "https://www.discord.gg/gymcommunity", // Update this with actual Patreon URL
          icon: <SiPatreon className="dropdown-icon" />
        },
        {
          title: "YouTube",
          path: "https://www.youtube.com/@TheGymCommunitygg",
          icon: <AiFillYoutube className="dropdown-icon" />
        },
        {
          title: "Blog",
          path: "https://medium.com/@ogbroly",
          icon: <SiMedium className="dropdown-icon" />
        },
        {
          title: "Paid Training",
          path: "https://www.egreen.training",
          icon: <BiDollarCircle className="dropdown-icon" />
        }
      ]
    }
  ];

  // Store configuration
  const storeDropdown = {
    type: "dropdown",
    title: "Store",
    icon: <FaStore className="nav-icon" />,
    items: [
      {
        title: "Free Programs",
        path: "https://beacons.ai/thegymcommunity",
        icon: <GiGymBag className="dropdown-icon" />
      },
      {
        title: "Equipment",
        path: "/",
        icon: <FaDumbbell className="dropdown-icon" />
      },
      {
        title: "Supplements",
        path: "/",
        icon: <FaBookOpen className="dropdown-icon" />
      }
    ]
  };

  // Uncomment this line and add it to navItems if you want to enable the Store dropdown
  // navItems.splice(3, 0, storeDropdown);

  useEffect(() => {
    function scrollHandler() {
      if (window.scrollY >= 20) {
        updateNavbar(true);
      } else {
        updateNavbar(false);
      }
    }

    window.addEventListener("scroll", scrollHandler);
    
    // Clean up event listener
    return () => {
      window.removeEventListener("scroll", scrollHandler);
    };
  }, []);

  const openInNewTab = (url) => {
    window.open(url, "_blank", "noreferrer");
  };

  // Render nav items based on configuration
  const renderNavItem = (item, index) => {
    if (item.type === "link") {
      if (item.internal) {
        return (
          <Nav.Item key={index}>
            <Nav.Link 
              as={Link} 
              to={item.path} 
              onClick={() => updateExpanded(false)}
              className="nav-link"
            >
              {item.icon}
              <span className="nav-link-text">{item.title}</span>
            </Nav.Link>
          </Nav.Item>
        );
      } else {
        return (
          <Nav.Item key={index}>
            <Nav.Link
              as={Link}
              to="#"
              onClick={() => { 
                openInNewTab(item.path);
                updateExpanded(false);
              }}
              className="nav-link"
            >
              {item.icon}
              <span className="nav-link-text">{item.title}</span>
            </Nav.Link>
          </Nav.Item>
        );
      }
    } else if (item.type === "dropdown") {
      return (
        <Dropdown as={Nav.Item} key={index}>
          <Dropdown.Toggle as={Nav.Link} className="nav-link">
            {item.icon}
            <span className="nav-link-text">{item.title}</span>
          </Dropdown.Toggle>
          <Dropdown.Menu>
            {item.items.map((dropdownItem, dropdownIndex) => (
              <Dropdown.Item
                key={dropdownIndex}
                onClick={() => {
                  openInNewTab(dropdownItem.path);
                  updateExpanded(false);
                }}
              >
                {dropdownItem.icon}
                <span className="ms-2">{dropdownItem.title}</span>
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
      );
    }
    return null;
  };

  return (
    <Navbar
      expanded={expand}
      fixed="top"
      expand="md"
      className={navColour ? "sticky" : "navbar"}
    >
      <Container>
        <Navbar.Brand href="/" className="d-flex align-items-center">
          <img 
            src={logo} 
            alt="The Gym Community Logo" 
            className="navbar-logo" 
            height="40" 
          />
          <div className="brand-text ms-2">The Gym Community</div>
        </Navbar.Brand>

        <Navbar.Toggle
          aria-controls="responsive-navbar-nav"
          onClick={() => {
            updateExpanded(expand ? false : "expanded");
          }}
        >
          <span></span>
          <span></span>
          <span></span>
        </Navbar.Toggle>

        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="ms-auto" defaultActiveKey="#home">
            {navItems.map((item, index) => renderNavItem(item, index))}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default NavBar;